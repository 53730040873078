<template>
    <div class="mt-n6">
        <v-container class="py-16">
            <v-row>
                <v-col sm="8" cols="12" style="height: 400px;">
                    <v-img
                        max-height="400"
                        :src="announcement.image"
                        alt="https://picsum.photos/id/11/10/6"
                    ></v-img>
                </v-col>
                <v-col sm="4" cols="12"></v-col>
            </v-row>
            <v-row class="mt-10">
                <v-col sm="8" cols="12" class="text-left">
                    <label class="d-block yellow--text text--darken-3 font-weight-bold">
                        {{ announcement.topic }}
                    </label>
                    <label class="py-2 d-block text-caption grey--text">
                        {{ thaiDate(new Date(announcement.createdAt ))}}
                    </label>
                    <label class="d-block pt-1 grey--text text--lighten-2">
                        {{ announcement.content }}
                    </label>
                </v-col>
                <v-col sm="4" cols="12" class="text-left">
                    <label class="d-block yellow--text text--darken-3 font-weight-bold">
                        ข่าวอื่นๆ
                    </label>
                    <div class="div-other py-5 grey--text text--lighten-2" 
                        @click="go(a.id)"
                        v-for="a of announcements" :key="a.id">
                        {{ convertShortContent(a.content) }}
                    </div>
                    <div class="text-center mt-4" style="width: 100%">
                        <v-btn
                        color="yellow darken-3"
                        rounded
                        outlined
                        class="px-10"
                        @click="$router.push({ name: 'AnnouncementHome'})"
                        >
                            ดูข่าวทั้งหมด
                        </v-btn>
                    </div>
                    
                </v-col>
            </v-row>
        </v-container>

        <!-- Util conponents -->
        <dialog-error
            ref="dialogError"
            :topic="'Error'"
            :text="errorMessage"
        />
    </div>
</template>

<script>
import axios from 'axios';
import DialogError from '../../components/DialogError.vue';
import { thaiDate } from '../../utils/text-mapping';

export default {
    data: () => ({
        announcements: [ // random it.
            { id: '1' },
            { id: '2' },
            { id: '3' },
            { id: '4' }
        ],
        announcement: {},
        errorMessage: '',
        thaiDate
    }),
    methods: {
        go(id){
            this.$router.push({ name: 'AnnouncementDetails', params: { id: id } }).catch(() => {});
        },
        convertShortContent(text){
            return text && text.length >= 71 ? text.toString().substring(0, 71) : text;
        },
        async getAnnouncement(){
            const loader = this.$loading.show();

            try{
                const id = this.$route.params.id;
                const response = await axios.get(process.env.VUE_APP_BASE_URL + '/contents/' + id);
                this.announcement = response.data;

                // random announcements
                let random = [];
                let all = [];
                // let selectedIndex = [];
                const response2 = await axios.get(process.env.VUE_APP_BASE_URL + '/contents?isPublish=true');
                all = response2.data;
                const indexCurrentAnnouncement = all.findIndex(a => a.id == id);
                all.splice(indexCurrentAnnouncement, 1);
                const length = all.length;
                for(let i = 0; i < length && i < 4; i++){ // not shuffle
                    let r1 = 98754321;
                    let runIt = true;
                    while(runIt){
                        r1 = Math.floor(Math.random() * length);
                        if(-1 == random.indexOf(r1)){
                            random.push(r1);
                            runIt = false;
                        }
                    }
                }

                this.announcements = [];
                random.forEach(r => this.announcements.push(all[r]));
                
            }catch(error){
                this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                this.$refs.dialogError.show();
            }finally{
                this.$vuetify.goTo( 0, {
                    duration: 0,
                    offset: 0,
                    easing: 'easeInOutCubic'
                });
                loader.hide();
            }
        }
    },
    async mounted(){
        this.getAnnouncement();
    },
    watch: {
        '$route.params.id'(){
            this.getAnnouncement();
        }
    },
    components: {
        DialogError
    }
};
</script>

<style scoped>
    .div-other{
        cursor: pointer;
        border-bottom: 1px solid #999999;

    }
    .div-other:hover{
        border-bottom: 1px solid #F9A825;
        background-color: #263238;
    }
</style>